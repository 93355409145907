/*
 * The Typekit service used to deliver this font or fonts for use on websites
 * is provided by Adobe and is subject to these Terms of Use
 * http://www.adobe.com/products/eulas/tou_typekit. For font license
 * information, see the list below.
 *
 * museo-slab:
 *   - http://typekit.com/eulas/0000000000000000000100fe
 *   - http://typekit.com/eulas/000000000000000000010141
 *   - http://typekit.com/eulas/000000000000000000012043
 *   - http://typekit.com/eulas/00000000000000000000ec08
 *   - http://typekit.com/eulas/000000000000000000011aff
 *   - http://typekit.com/eulas/00000000000000000000ec0a
 *
 * © 2009-2020 Adobe Systems Incorporated. All Rights Reserved.
 */
/*{"last_published":"2019-03-11 02:58:51 UTC"}*/

@import url("https://p.typekit.net/p.css?s=1&k=hbo5hsa&ht=tk&f=2028.2029.2030.2031.2032.2033&a=15741954&app=typekit&e=css");

@font-face {
font-family:"museo-slab";
src:url("https://use.typekit.net/af/53dec0/0000000000000000000100fe/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3") format("woff2"),url("https://use.typekit.net/af/53dec0/0000000000000000000100fe/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3") format("woff"),url("https://use.typekit.net/af/53dec0/0000000000000000000100fe/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3") format("opentype");
font-display:auto;font-style:normal;font-weight:300;
}

@font-face {
font-family:"museo-slab";
src:url("https://use.typekit.net/af/ea0e14/000000000000000000010141/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i3&v=3") format("woff2"),url("https://use.typekit.net/af/ea0e14/000000000000000000010141/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i3&v=3") format("woff"),url("https://use.typekit.net/af/ea0e14/000000000000000000010141/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i3&v=3") format("opentype");
font-display:auto;font-style:italic;font-weight:300;
}

@font-face {
font-family:"museo-slab";
src:url("https://use.typekit.net/af/aa4f4e/000000000000000000012043/27/l?subset_id=2&fvd=n5&v=3") format("woff2"),url("https://use.typekit.net/af/aa4f4e/000000000000000000012043/27/d?subset_id=2&fvd=n5&v=3") format("woff"),url("https://use.typekit.net/af/aa4f4e/000000000000000000012043/27/a?subset_id=2&fvd=n5&v=3") format("opentype");
font-display:auto;font-style:normal;font-weight:500;
}

@font-face {
font-family:"museo-slab";
src:url("https://use.typekit.net/af/f1892e/00000000000000000000ec08/27/l?subset_id=2&fvd=i5&v=3") format("woff2"),url("https://use.typekit.net/af/f1892e/00000000000000000000ec08/27/d?subset_id=2&fvd=i5&v=3") format("woff"),url("https://use.typekit.net/af/f1892e/00000000000000000000ec08/27/a?subset_id=2&fvd=i5&v=3") format("opentype");
font-display:auto;font-style:italic;font-weight:500;
}

@font-face {
font-family:"museo-slab";
src:url("https://use.typekit.net/af/c225e2/000000000000000000011aff/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("woff2"),url("https://use.typekit.net/af/c225e2/000000000000000000011aff/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("woff"),url("https://use.typekit.net/af/c225e2/000000000000000000011aff/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("opentype");
font-display:auto;font-style:normal;font-weight:700;
}

@font-face {
font-family:"museo-slab";
src:url("https://use.typekit.net/af/696cdf/00000000000000000000ec0a/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3") format("woff2"),url("https://use.typekit.net/af/696cdf/00000000000000000000ec0a/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3") format("woff"),url("https://use.typekit.net/af/696cdf/00000000000000000000ec0a/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3") format("opentype");
font-display:auto;font-style:italic;font-weight:700;
}

.tk-museo-slab { font-family: "museo-slab",serif; }

html {
  scroll-behavior: smooth;
  position: relative;
  min-height: 100%;
}
body {
  margin-bottom: 143px;
}

* {
  font-family: 'Avenir Next', sans-serif !important;
}

a.tlf-button-link,
a.tlf-button-link:hover,
a.tlf-button-link:visited,
a.tlf-button-link:active {
  color: #fff;
  text-decoration: inherit;
}

.tlf-button-link {
  color: inherit;
  text-decoration: inherit;
}

.tlf-section-headline {
  margin-bottom: 30px;
}
.tlf-section-headline h2 {
  font-size: 34px !important;
}
@media all and (min-width: 768px) {
  .tlf-section-headline {
    margin-bottom: 35px;
  }
  .tlf-section-headline h2 {
    font-size: 40px !important;
  }
}
@media all and (min-width: 1024px) {
  .tlf-section-headline {
    margin-bottom: 40px;
  }
  .tlf-section-headline h2 {
    font-size: 44px !important;
  }
}

#tlf-header .tlf-logo {
  padding: 0;
  width: 100%;
}
#tlf-header .tlf-logo img {
  height: auto;
  width: 163px;
}
#tlf-header .tlf-header-links {
  justify-content: space-around;
  padding-top: 14px;
  width: 100%;
}
#tlf-header a,
#tlf-header a:hover,
#tlf-header a:visited,
#tlf-header a:active {
  color: inherit;
  text-decoration: inherit;
}
@media all and (min-width: 768px) {
  #tlf-header .tlf-logo {
    width: auto;
  }
  #tlf-header .tlf-logo img {
    width: 198px;
  }
  #tlf-header .tlf-header-links {
    justify-content: flex-end;
    padding-top: 0;
    width: auto;
  }
  #tlf-header .tlf-header-links > *:not(:last-child) {
    margin-right: 55px;
  }
}

.tlf-header-carousel .carousel-wrapper {
  width: 400%;
  position: relative;
  text-align: center;
  list-style: none;
  transition: margin 0.5s;
  height: 190px !important;
}
.tlf-header-carousel .arrow-to-left {
  left: 18px;
}
.tlf-header-carousel .arrow-to-right {
  right: 18px;
}
.tlf-header-carousel .header-copy {
  font-size: 20px;
  padding: 10px 20px;
  width: 268px;
}
.tlf-header-carousel .arrow {
  height: 25px;
  width: auto;
}
@media all and (min-width: 768px) {
  .tlf-header-carousel .carousel-wrapper {
    height: 402px !important;
  }
  .tlf-header-carousel .arrow-to-left {
    left: 50px;
  }
  .tlf-header-carousel .arrow-to-right {
    right: 50px;
  }
  .tlf-header-carousel .header-copy {
    font-size: 30px;
    padding: 20px 40px;
    width: 634px;
  }
}

#store-locations-section div[class^='col-'] {
  height: 190px;
}
@media all and (min-width: 768px) {
  #store-locations-section div[class^='col-'] {
    height: 260px;
  }
}
#store-locations-section a,
#store-locations-section a:hover,
#store-locations-section a:visited,
#store-locations-section a:active {
  color: inherit;
  text-decoration: inherit;
}

#tlf-copy-section .tlf-copy-row {
  flex-direction: row;
}
#tlf-copy-section .tlf-copy-column {
  width: 100%;
}
#tlf-copy-section div.tlf-copy-column {
  padding: 46px 28px;
}
#tlf-copy-section .tlf-copy-column-headline {
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 40px;
}
#tlf-copy-section .tlf-copy-column-text {
  font-size: 20px;
}
@media all and (min-width: 768px) {
  #tlf-copy-section .tlf-copy-row.inverted {
    flex-direction: row-reverse;
  }
  #tlf-copy-section .tlf-copy-column {
    width: 50%;
  }
  #tlf-copy-section div.tlf-copy-column {
    padding: 0 52px;
  }
  #tlf-copy-section .tlf-copy-column-headline {
    font-size: 32px;
    font-weight: 500;
    margin-bottom: 40px;
  }
  #tlf-copy-section .tlf-copy-column-text {
    font-size: 24px;
  }
}

#tlf-reviews-section {
  min-height: 450px;
}
#tlf-reviews-section .carousel-wrapper {
  width: 400%;
  position: relative;
  text-align: center;
  padding: 84px 0;
  list-style: none;
  transition: margin 0.5s;
}
#tlf-reviews-section .review-container {
  padding: 0 28px;
}
#tlf-reviews-section .review {
  width: 100%;
  margin-bottom: 56px;
}
#tlf-reviews-section .review .reviewer-photo {
  margin-bottom: 25px;
}
#tlf-reviews-section .review .reviewer-message {
  font-size: 18px;
}
#tlf-reviews-section .item {
  width: 100%;
}
#tlf-reviews-section .item img {
  height: 150px;
  width: auto;
}
#tlf-reviews-section .yelp-and-google {
  display: none;
}
#tlf-reviews-section .arrow {
  height: 25px;
  width: auto;
}
@media (min-width: 768px) {
  #tlf-reviews-section .carousel-wrapper {
    padding: 148px 0 56px;
  }
  #tlf-reviews-section .review-container {
    padding: 0 40px;
  }
  #tlf-reviews-section .review {
    width: 50%;
    margin-bottom: 0;
  }
  #tlf-reviews-section .review .reviewer-photo {
    margin-bottom: 18px;
  }
  #tlf-reviews-section .review .reviewer-message {
    background-color: transparent;
    font-size: 24px;
  }
  #tlf-reviews-section .item {
    width: 50%;
  }
  #tlf-reviews-section .item img {
    height: 250px;
    width: auto;
  }
  #tlf-reviews-section .yelp-and-google {
    display: block;
    margin-top: 102px;
    width: 100%;
  }
}

#tlf-i-have-items-to-sell-section {
  padding: 64px 0 208px;
  background-color: #f7f8f6;
}
#tlf-i-have-items-to-sell-section .headline {
  color: #000000;
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 38px;
  text-align: center;
}
#tlf-i-have-items-to-sell-section .subheadline {
  color: #000000;
  font-size: 14px;
  margin-bottom: 25px;
  padding: 0 20px;
  text-align: center;
}
#tlf-i-have-items-to-sell-section .go-to-remoov {
  margin-bottom: 64px;
  text-align: center;
}
#tlf-i-have-items-to-sell-section .cards-list {
  padding: 0 64px;
  transform: translateY(144px);
}
#tlf-i-have-items-to-sell-section .tlf-card {
  margin-bottom: 20px;
  width: 100%;
}
@media all and (min-width: 768px) {
  #tlf-i-have-items-to-sell-section {
    background-color: #fbfbfb;
    padding: 180px 0;
  }
  #tlf-i-have-items-to-sell-section .headline {
    font-size: 32px;
    margin-bottom: 18px;
    text-align: center;
  }
  #tlf-i-have-items-to-sell-section .subheadline {
    font-size: 20px;
    margin-bottom: 45px;
    padding: 0;
  }
  #tlf-i-have-items-to-sell-section .go-to-remoov {
    margin-bottom: 180px;
  }
  #tlf-i-have-items-to-sell-section .cards-container {
    background-size: auto !important;
  }
  #tlf-i-have-items-to-sell-section .cards-list {
    padding: 0;
    transform: translateY(-50px);
  }
  #tlf-i-have-items-to-sell-section .tlf-card {
    width: calc(33.33% - (4 * 5px) - (2 * 5px));
    margin-left: 10px;
    margin-right: 10px;
  }
  #tlf-i-have-items-to-sell-section .tlf-card:first-child {
    margin-left: 0;
  }
  #tlf-i-have-items-to-sell-section .tlf-card:last-child {
    margin-right: 0;
  }
}
@media all and (min-width: 1024px) {
  #tlf-i-have-items-to-sell-section .cards-container {
    padding-bottom: 110px;
  }
}
#tlf-i-have-items-to-sell-section a,
#tlf-i-have-items-to-sell-section a:hover,
#tlf-i-have-items-to-sell-section a:visited,
#tlf-i-have-items-to-sell-section a:active {
  color: inherit;
  text-decoration: inherit;
}

#tlf-footer {
  position: absolute;
  left: 0;
  bottom: 0;
  height: 143px;
  width: 100%;
  overflow: hidden;
  background: white;
}
#tlf-footer a,
#tlf-footer a:hover,
#tlf-footer a:visited,
#tlf-footer a:active {
  color: inherit;
  text-decoration: inherit;
}
#tlf-footer a:not(:last-child) {
  margin-right: 20px;
}
#tlf-footer .social-medias-container {
  margin-bottom: 24px;
}
#tlf-footer .the-flet-market {
  color: #000000;
  font-size: 12px;
  margin-bottom: 37px;
}
@media all and (min-width: 768px) {
  #tlf-footer a:not(:last-child) {
    margin-right: 34px;
  }
  #tlf-footer .the-flet-market {
    font-size: 16px;
    margin-bottom: 32px;
  }
}
